<script>
	import ButtonLoader from './ButtonLoader.svelte';

	export let disabled = false;
	export let loading = false;
	export let secondary = false;
	export let style = '';
	export let type = 'button';
	export let warning = false;
	export let small = false;
	export let dataTestId = null;
</script>

<button
	data-testid={dataTestId}
	disabled={disabled || loading}
	{type}
	{style}
	class:secondary
	class:loading
	class:warning
	class:small
	on:click
>
	{#if loading && small}
		<!-- for small button loaders, due to their dynamic width, position the loader absolutely on top of hidden content -->
		<ButtonLoader absolute={true} />
		<slot />
	{:else if loading}
		<ButtonLoader />
	{:else}
		<slot />
	{/if}
</button>

<style>
	button {
		padding: 15px 20px;
		border-radius: 30px;
		background-color: var(--primary);
		border: 0px;
		color: white;
		font-weight: 600;
		box-sizing: border-box;
		width: 400px;
		max-width: 100%;
		cursor: pointer;
		transition: background-color 0.15s linear;
		transition: box-shadow 0.3s linear;
	}
	button:hover,
	button:active,
	button:focus {
		background-color: var(--primaryDark);
	}
	button:focus {
		box-shadow: 0 0 0 3px rgba(142, 203, 236, 0.5);
	}
	button.loading {
		padding: 9px 20px;
	}
	button:disabled {
		background-color: var(--disabledButton);
		color: var(--disabledText);
	}
	button:disabled.loading {
		background-color: var(--primary);
		color: white;
		opacity: 0.8;
	}
	button.secondary {
		background-color: white;
		color: var(--primary);
		border: 2px solid var(--primary);
		box-sizing: border-box;
	}
	button.secondary:hover {
		color: var(--primaryDark);
		border: 2px solid var(--primaryDark);
	}
	button.warning {
		background-color: var(--warning);
	}
	button.warning:hover {
		background-color: var(--warningDark);
	}
	button:disabled.warning {
		background-color: var(--disabledButton);
		color: var(--disabledText);
	}
	button.warning.loading {
		background-color: var(--warning);
	}
	button.small {
		width: auto;
		padding: 12px 22px;
		position: relative;
	}
	button.small.loading,
	button.small.loading:hover,
	button.small.loading:active,
	button.small.loading:focus {
		color: transparent;
	}

	@media screen and (max-width: 550px) {
		button {
			width: 350px;
			max-width: 100%;
		}
	}
</style>
